<template>
  <NavBar />
  <div class="container">
    <router-link
      :to="{ name: 'ViewCategories', params: { locationId: locationId } }"
    >
      <button class="btn btn-info float-start">
        Back To Categouries
      </button></router-link
    >
    <router-link
      :to="{ name: 'LocationMenu', params: { locationId: locationId } }"
    >
      <button class="btn btn-success float-start">
        Back To Menu
      </button></router-link
    >
    <br />
    <div class="text-center">
      <h1 class="mb-0">{{ locName }}</h1>
      <p class="text-muted">{{ locAddress }}</p>
    </div>
    <br />
    <form @click.prevent>
      <div class="row g-3 align-items-center">
        <div class="col-auto d-block mx-auto">
          <input
            type="text"
            class="form-control"
            placeholder="Add Category Name"
            v-model.trim="name"
          />
          <span class="error-feedback" v-if="v$.name.$error">{{
            v$.name.$errors[0].$message
          }}</span>
        </div>
      </div>
      <br />
      <div class="row g-3 align-items-center">
        <div
          class="col-auto d-block mx-auto alert alert-success"
          v-if="successMessage.length > 0"
        >
          {{ successMessage }}
        </div>
        <div
          class="col-auto d-block mx-auto alert alert-danger"
          v-if="errorMessage.length > 0"
        >
          {{ errorMessage }}
        </div>
      </div>
      <br />
      <div class="row g-3 align-items-center">
        <div class="col-auto d-block mx-auto">
          <button
            type="button"
            @click="updateCategroy()"
            class="btn btn-success"
          >
            Update Now
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import NavBar from "../components/NavBar.vue";
import useValidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import { mapActions, mapMutations } from "vuex";
import axios from "axios";
export default {
  name: "UpdateCategory",
  components: {
    NavBar,
  },
  data() {
    return {
      v$: useValidate(),
      locationId: this.$route.params.locationId,
      catId: this.$route.params.catId,
      userId: "",
      locName: "",
      locAddress: "",
      name: "",
      successMessage: "",
      errorMessage: "",
      listOfCategories: [],
      categroyName: [],
    };
  },
  validations() {
    return {
      name: { required, minLength: minLength(3), maxLength: maxLength(15) },
    };
  },
  mounted() {
    let user = localStorage.getItem("user-info");
    if (!user) {
      this.redirectTo({ val: "SignUp" });
    } else {
      this.userName = JSON.parse(user).name;
      this.userId = JSON.parse(user).id;
      this.canUserAccessThisLocation({
        userIdIs: this.userId,
        locationIdIs: this.locationId,
        redirectToPage: "home",
      });
      this.canUserAccessThisCategory({
        userIdIs: this.userId,
        catIdIs: this.catId,
        locationIdIs: this.locationId,
        redirectToPage: "home",
      });
    }
    this.getLocationInfo(this.userId, this.locationId);
    this.listOfUserCategories(this.userId, this.locationId);
    this.getCategoryName(this.userId, this.locationId, this.catId);
  },
  methods: {
    ...mapMutations([
      "isLoggedInUser",
      "displayAllCategories",
      "canUserAccessThisCategory",
      "canUserAccessThisLocation",
    ]),
    ...mapActions(["redirectTo"]),
    async listOfUserCategories(userId, locId) {
      let result = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/data/Categories?userId=${userId}&locationId=${locId}`
      );
      if (result.status == 200) {
        this.listOfCategories = result.data;
      }
    },
    async getLocationInfo(userId, locId) {
      let result = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/data/locations?userId=${userId}&id=${locId}`
      );
      let locDetails = [];
      if (result.status == 200 && result.data.length > 0) {
        locDetails = result.data;
        this.locName = locDetails[0].name;
        this.locAddress = locDetails[0].address;
      }
    },
    async getCategoryName(userId, locId, catId) {
      let result = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/data/categories?userId=${userId}&locationId=${locId}&id=${catId}`
      );
      if (result.status == 200) {
        this.categroyName = result.data;
        if (this.categroyName.length > 0) {
          this.name = this.categroyName[0].name;
        }
      }
    },
    async updateCategroy() {
      this.v$.$validate();
      let filterCategoryName = this.listOfCategories.filter(
        (v) => v.name.toLocaleLowerCase() == this.name.toLocaleLowerCase()
      );
      console.table(this.listOfCategories);
      console.table(filterCategoryName);

      if (!this.v$.$error) {
        if (filterCategoryName.length > 0) {
          this.successMessage = "";
          this.errorMessage =
            "Category Name is Already exist , try another name";
        } else {
          let result = await axios.put(
            `https://my-json-server.typicode.com/y-alhouri/data/Categories/${this.catId}`,
            {
              name: this.name,
              userId: this.userId,
              locationId: parseInt(this.locationId),
            }
          );
          if (result.status == 200) {
            this.successMessage = "Updated Category Successfully ";
            this.errorMessage = "";
            setTimeout(() => {
              this.$router.push({
                name: "ViewCategories",
                params: { locationId: this.locationId },
              });
            }, 2000);
          } else {
            this.successMessage = "";
            this.errorMessage = "Some thing went error, Try again..";
          }
        }
      } else {
        this.successMessage = "";
        this.errorMessage = "You must fill in Category Name";
      }
    },
  },
};
</script>

<!-- <template>h</template>
<script>
export default {
  name: "UpdateCategory",
  data() {
    return {};
  },
};
</script> -->
