<template>
  <div class="container">
    <NavBar />
    <form @click.prevent>
      <div class="row g-3 align-items-center">
        <div class="col-auto mx-auto d-block">
          <h1>Delete All Locations</h1>
          <hr />
          <p class="text-danger">
            Are you sure!,Do you want to delete All locations?
          </p>
          <hr />
        </div>
      </div>
      <div class="row g-3 align-items-center">
        <div class="col-auto mx-auto d-block">
          <button class="btn btn-info" @click="goBack()">Go Back</button
          >&nbsp;&nbsp;&nbsp;<button
            class="btn btn-danger"
            @click="delAllLocations()"
          >
            Delete All Now
          </button>
        </div>
      </div>
      <br />
      <div class="row g-3 align-items-center">
        <div class="col-auto mx-auto d-block">
          <div
            class="col-auto d-block mx-auto alert alert-warning"
            v-if="successMessage.length > 0"
          >
            {{ successMessage }}
          </div>
          <div
            class="col-auto d-block mx-auto alert alert-danger"
            v-if="errorMessage.length > 0"
          >
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "DeleteAllLocations",
  components: {
    NavBar,
  },
  data() {
    return {
      userId: "",
      successMessage: "",
      errorMessage: "",
      allLocationsId: [],
      allItemsIdIs: [],
      allCatsIdIs: [],
    };
  },
  async mounted() {
    let user = localStorage.getItem("user-info");
    if (!user) {
      this.redirectTo({ val: "SignUp" });
    } else {
      this.userId = JSON.parse(user).id;
      //Items
      let itemsResult = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/data/items?userId=${this.userId}`
      );
      console.log(itemsResult.data.length);
      let itemsResultlen = itemsResult.data.length;
      for (var k = 0; k < itemsResultlen; k++) {
        this.allItemsIdIs.push(itemsResult.data[k].id);
      }
      //Categrories
      let catsResult = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/data/categories?userId=${this.userId}`
      );
      console.log(catsResult.data.length);
      let catsResultlen = catsResult.data.length;
      for (var j = 0; j < catsResultlen; j++) {
        this.allCatsIdIs.push(catsResult.data[j].id);
      }

      //Locations
      let LocResult = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/data/locations?userId=${this.userId}`
      );
      console.log(LocResult.data.length);
      let LocResultlen = LocResult.data.length;
      for (var i = 0; i < LocResultlen; i++) {
        this.allLocationsId.push(LocResult.data[i].id);
      }
      console.table(this.allLocationsId);
    }
  },
  methods: {
    ...mapActions(["redirectTo"]),
    goBack() {
      this.redirectTo({ val: "SignUp" });
    },
    async delAllLocations() {
      // Items
      let allItemsResults = [];
      for (var i = 0; i < this.allItemsIdIs.length; i++) {
        let result = await axios.delete(
          `https://my-json-server.typicode.com/y-alhouri/data/items/${this.allItemsIdIs[i]}`
        );
        if (result.status == 200) {
          allItemsResults.push(true);
        } else {
          allItemsResults.push(false);
        }
      }
      // categroies
      let allCatsResults = [];
      for (var m = 0; m < this.allCatsIdIs.length; m++) {
        let result = await axios.delete(
          `https://my-json-server.typicode.com/y-alhouri/data/categories/${this.allCatsIdIs[m]}`
        );
        if (result.status == 200) {
          allCatsResults.push(true);
        } else {
          allCatsResults.push(false);
        }
      }
      //Locations
      let allLocsResults = [];
      for (var l = 0; l < this.allLocationsId.length; l++) {
        let result = await axios.delete(
          `https://my-json-server.typicode.com/y-alhouri/data/locations/${this.allLocationsId[l]}`
        );
        if (result.status == 200) {
          allLocsResults.push(true);
        } else {
          allLocsResults.push(false);
        }
      }
      // console.log(allResults);
      if (
        !allLocsResults.includes(false) &&
        !allCatsResults.includes(false) &&
        !allItemsResults.includes(false)
      ) {
        this.successMessage = "All locations are deleted ..";
        setTimeout(() => {
          this.redirectTo({ val: "home" });
        }, 1000);
      } else {
        console.log("Something went error ");
        this.errorMessage = "Something went error";
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
