<template>
  <div class="container">
    <NavBar />
    <form @click.prevent>
      <div class="row g-3 align-items-center">
        <div class="col-auto mx-auto d-block">
          <h1>Delete all Categroies</h1>
          <hr />
          <p class="text-danger">
            Are you sure!,Do you want to delete all Categories for this
            location?
          </p>
          <br />
          <div class="text-center">
            <h1 class="mb-0">{{ locName }}</h1>
            <p class="text-muted">{{ locAddress }}</p>
          </div>
          <hr />
        </div>
      </div>
      <div class="row g-3 align-items-center">
        <div class="col-auto mx-auto d-block">
          <button class="btn btn-info" @click="goBack()">Go Back</button
          >&nbsp;&nbsp;&nbsp;<button
            class="btn btn-danger"
            @click="delAllCategory()"
          >
            Delete Now
          </button>
        </div>
      </div>
      <br />
      <div class="row g-3 align-items-center">
        <div class="col-auto mx-auto d-block">
          <div
            class="col-auto d-block mx-auto alert alert-warning"
            v-if="successMessage.length > 0"
          >
            {{ successMessage }}
          </div>
          <div
            class="col-auto d-block mx-auto alert alert-danger"
            v-if="errorMessage.length > 0"
          >
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  name: "DeleteLocation",
  components: {
    NavBar,
  },
  data() {
    return {
      locationId: this.$route.params.locationId,
      userId: "",
      locationData: [],
      successMessage: "",
      errorMessage: "",
      name: "",
      allItemsIdIs: [],
      allCatsIdIs: [],
      locName: "",
      locAddress: "",
    };
  },
  async mounted() {
    let user = localStorage.getItem("user-info");
    if (!user) {
      this.redirectTo({ val: "SignUp" });
    } else {
      this.deleteLocation = this.$route.params.locationId;
      this.userId = JSON.parse(user).id;
      this.canCurrentUserAccessThisLocation();
      this.getLocationInfo(this.userId, this.locationId);
      let result = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/data/items?locId=${this.locationId}`
      );
      let resultlen = result.data.length;
      for (var i = 0; i < resultlen; i++) {
        this.allItemsIdIs.push(result.data[i].id);
      }
      let resultForCategories = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/data/categories?locationId=${this.locationId}`
      );
      let resultForCategorieslen = resultForCategories.data.length;
      for (var j = 0; j < resultForCategorieslen; j++) {
        this.allCatsIdIs.push(resultForCategories.data[j].id);
      }
    }
  },
  methods: {
    ...mapActions(["redirectTo"]),
    goBack() {
      this.$router.push({
        name: "ViewCategories",
        params: { locationId: this.locationId },
      });
    },
    async getLocationInfo(userId, locId) {
      let result = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/data/locations?userId=${userId}&id=${locId}`
      );
      let locDetails = [];
      if (result.status == 200 && result.data.length > 0) {
        locDetails = result.data;
        this.locName = locDetails[0].name;
        this.locAddress = locDetails[0].address;
      }
    },
    async canCurrentUserAccessThisLocation() {
      let result = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/data/locations?id=${this.deleteLocation}&userId=${this.userId}`
      );
      if (result.status == 200 && result.data.length > 0) {
        this.locationData = result.data;
        this.name = this.locationData[0].name;
        this.address = this.locationData[0].address;
        this.phone = this.locationData[0].phone;
      } else {
        this.redirectTo({ val: "home" });
      }
    },
    async delAllCategory() {
      let allCatsResults = [];
      for (var k = 0; k < this.allCatsIdIs.length; k++) {
        let result = await axios.delete(
          `https://my-json-server.typicode.com/y-alhouri/data/categories/${this.allCatsIdIs[k]}`
        );
        if (result.status == 200) {
          allCatsResults.push(true);
        } else {
          allCatsResults.push(false);
        }
      }
      let allResults = [];
      for (var i = 0; i < this.allItemsIdIs.length; i++) {
        let result = await axios.delete(
          `https://my-json-server.typicode.com/y-alhouri/data/items/${this.allItemsIdIs[i]}`
        );
        if (result.status == 200) {
          allResults.push(true);
        } else {
          allResults.push(false);
        }
      }
      if (!allCatsResults.includes(false) && !allResults.includes(false)) {
        this.successMessage = "Category and related items are all deleted ..";
        setTimeout(() => {
          this.$router.push({
            name: "ViewCategories",
            params: { locationId: this.locationId },
          });
        }, 1000);
      } else {
        console.log("Something went error ");
        this.errorMessage = "Something went error";
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
